import React, { useCallback, useMemo } from "react";
import { CellProps } from "./Cell";
import { BASE_COLUMN_TYPES, ColumnDataMap } from "../config/types";
import { Csku, TrendIcon, Typography, colors } from "@commonsku/styles";

type TColumn = typeof BASE_COLUMN_TYPES.Percentage;
const PercentageCell = ({
  column,
  row,
  className,
  onClick,
  style = {},
  textStyle = {},
}: CellProps<TColumn>) => {
  const {
    accessorKey,
    dataTransform,
    percentageOptions: { decimalPlaces = 0, includeTrendline = false },
  } = column;

  const { percentage } = useMemo<ColumnDataMap<TColumn>>(() => {
    const rawData = row.original[accessorKey];
    if (dataTransform) {
      const { percentage } = dataTransform(rawData);
      return { percentage };
    }

    const key = Object.keys(rawData);
    if (key.length !== 1)
      throw new Error(
        "PercentageCell can only handle one key if dataTransform is not provided",
      );
    const percentage = rawData[key[0]];

    return { percentage };
  }, [accessorKey, dataTransform, row.original]);

  const percentageText = useCallback(
    (decimal) => {
      if (percentage === null) {
        return <>&mdash;</>;
      }
      let percentageNumber = 0;
      if (typeof percentage === "string") {
        percentageNumber = parseFloat(percentage);
      } else {
        percentageNumber = percentage;
      }
      let percentageContent =
        Infinity === percentageNumber
          ? `∞`
          : -Infinity === percentageNumber
            ? `-∞`
            : percentageNumber.toFixed(decimal);
      if (!includeTrendline) {
        return `${percentageContent}%`;
      }

      // We add a nice trending icon with color as well
      let trendIcon = null;
      let color = "inherit";
      if (percentageNumber > 0) {
        color = colors.green[60];
        trendIcon = <TrendIcon direction="up" altText="Trending Up" />;
      }
      if (percentageNumber < 0) {
        color = colors.errors[60];
        trendIcon = <TrendIcon direction="down" altText="Trending Down" />;
      }
      let content = (
        <div
          style={{
            color: color,
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {trendIcon} {percentageContent}%
        </div>
      );
      if (percentageNumber === 0) {
        content = <TrendIcon direction="flat" />;
      }
      return content;
    },
    [percentage, includeTrendline],
  );

  return (
    <Csku className={className} onClick={onClick}>
      <div style={style} title={`${percentageText(2)}`}>
        <Typography.Span
          style={{
            display: "block",
            alignContent: "center",
            color: style.color,
            ...textStyle,
          }}
        >
          {percentageText(decimalPlaces)}
        </Typography.Span>
      </div>
    </Csku>
  );
};

export default PercentageCell;
